import { EndPoint } from "_enums/Endpoint";
import { ConfigurationError } from "_enums/Error";
import { ResponseCode } from "_enums/ResponseCode";
import { IConfigurationReceive, IConfigurationSend } from "_interfaces/Configuration";
import { api } from "_services/api";
import { formatError } from "_utils/Api";

/***
 * Executes a patch request to set user configuration
 * @returns IUserConfigurationReceive
 * @throws ConfigurationError, if request was not successful
 */
export const patchConfiguration = async (config: IConfigurationSend): Promise<void> => {
  try {
    // Make get request to server
    await api({
      method: "PATCH",
      url: EndPoint.CONFIGURATION,
      data: config,
    });
    return;
  } catch (error) {
    const responseError = formatError(error);
    switch (responseError) {
      case ResponseCode.UNAUTHORIZED:
        throw ConfigurationError.INVALID_CREDENTIALS;
    }
    throw ConfigurationError.DEFAULT;
  }
};
