import { Command } from "_enums/Command";
import { Localization } from "_interfaces/Localization";

// TODO - move this to constant, if/when localization will also be constant

export const getTranslationForCommand = (command: Command, localization: Localization) => {
  switch (command) {
    case Command.NEW_LINE:
      return localization.command.newLine;
    case Command.NEW_PARAGRAPH:
      return localization.command.newParagraph;
    case Command.DELETE_WORD:
      return localization.command.deleteWord;
    case Command.DELETE_SENTENCE:
      return localization.command.deleteSentence;
    case Command.SELECT:
      return localization.command.select;
    case Command.BOLD:
      return localization.command.boldNextWord;
    case Command.BOLD_ON:
      return localization.command.boldOn;
    case Command.BOLD_OFF:
      return localization.command.boldOff;
    case Command.ITALIC:
      return localization.command.italicNextWord;
    case Command.ITALIC_ON:
      return localization.command.italicOn;
    case Command.ITALIC_OFF:
      return localization.command.italicOff;
    case Command.UNDERLINE:
      return localization.command.underlineNextWord;
    case Command.UNDERLINE_ON:
      return localization.command.underlineOn;
    case Command.UNDERLINE_OFF:
      return localization.command.underlineOff;
    case Command.LOWER_CASE:
      return localization.command.lowerCaseNextWord;
    case Command.LOWER_CASE_ON:
      return localization.command.lowerCaseOn;
    case Command.LOWER_CASE_OFF:
      return localization.command.lowerCaseOff;
    case Command.SHIFT_CASE_NEXT_CHAR:
      return localization.command.shiftCaseNextChar;
    case Command.UPPER_CASE:
      return localization.command.upperCaseNextWord;
    case Command.UPPER_CASE_ON:
      return localization.command.upperCaseOn;
    case Command.UPPER_CASE_OFF:
      return localization.command.upperCaseOff;
    case Command.SPACE:
      return localization.command.space;
    case Command.FIND:
      return localization.command.find;
    case Command.LEFT:
    case Command.PREV:
      return localization.command.prev;
    case Command.RIGHT:
    case Command.NEXT:
      return localization.command.next;
    case Command.CANCEL:
      return localization.command.cancel;
    case Command.END:
      return localization.command.end;
    default:
      return "";
  }
};
