import React, { useEffect, useState } from "react";

interface UseOnlineReturn {
  isOnline: boolean;
}

const useOnline = (): UseOnlineReturn => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  const setOnline = () => setIsOnline(true);
  const setOffline = () => setIsOnline(false);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);
    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return { isOnline };
};

export default useOnline;
