import { WORD_DELIMITERS, SENTENCE_DELIMITERS } from "_constants/word";
import { Command } from "_enums/Command";
import { IContent } from "_interfaces/Transcript";
import { transcriptContentToText } from "_utils/Transcript";

export default class WordManager {
  private _bold: boolean;
  private _boldNextWord: boolean;
  private _italic: boolean;
  private _italicNextWord: boolean;
  private _underlined: boolean;
  private _underlinedNextWord: boolean;
  private _lowerCase: boolean;
  private _lowerCaseNextWord: boolean;
  private _upperCase: boolean;
  private _shiftCaseNextChar: boolean;
  private _upperCaseNextWord: boolean;

  private _interimRange: Word.Range | null;

  private _searchResults: Word.RangeCollection | null;
  private _searchIndex: number | null;
  private _searchMaxIndex: number | null;

  constructor() {
    this._bold = false;
    this._boldNextWord = false;
    this._italic = false;
    this._italicNextWord = false;
    this._underlined = false;
    this._underlinedNextWord = false;
    this._lowerCase = false;
    this._lowerCaseNextWord = false;
    this._shiftCaseNextChar = false;
    this._upperCaseNextWord = false;

    this._interimRange = null;

    this._searchResults = null;
    this._searchIndex = null;
    this._searchMaxIndex = null;
  }

  set bold(bold: boolean) {
    this._bold = bold;
  }

  set italic(italic: boolean) {
    this._italic = italic;
  }

  set underlined(underlined: boolean) {
    this._underlined = underlined;
  }

  set lowerCase(lowerCase: boolean) {
    this._lowerCase = lowerCase;
  }

  set upperCase(upperCase: boolean) {
    this._upperCase = upperCase;
  }

  /***
   * Common, utils
   */

  clearCurrentInterim = async () => {
    // Delete, untrack the current interim
    if (this._interimRange !== null) {
      await Word.run(this._interimRange, async (context) => {
        this._interimRange.delete();
        this._interimRange.untrack();
        await context.sync();
        this._interimRange = null;
      });
    }
  };

  private cancelSearchAndSelection = async () => {
    await Word.run(this._searchResults, async (context) => {
      // Cancel search
      if (this._searchResults) {
        this._searchResults.untrack();
      }
      this._searchResults = null;
      this._searchIndex = null;
      this._searchMaxIndex = null;

      // Cancel selection
      const range = context.document.body;
      range.select(Word.SelectionMode.start);
      await context.sync();
    });
  };

  private handleSelection = async (cmd: Command): Promise<boolean> => {
    let selection: Word.Range | null = null;
    await Word.run(async (context) => {
      selection = context.document.getSelection();
      await context.sync();
      selection.load("isEmpty");
      await context.sync();
      if (!selection || selection.isEmpty) {
        selection = null;
        return;
      }
      switch (cmd) {
        case Command.BOLD:
          selection.font.bold = true;
          break;
        case Command.ITALIC:
          selection.font.italic = true;
          break;
        case Command.UNDERLINE:
          selection.font.underline = Word.UnderlineType.word;
          break;
        case Command.DELETE_WORD:
          selection.delete();
          break;
        case Command.UPPER_CASE:
        case Command.UPPER_CASE_ON:
          selection.load("text");
          await context.sync();
          const upperCasedText = selection.text.toUpperCase();
          selection.insertText(upperCasedText, Word.InsertLocation.replace);
          // Set selection to null to continue the upper casing on next words
          if (cmd === Command.UPPER_CASE_ON) selection = null;
          break;
        default:
          selection = null;
      }
      await context.sync();
    });
    return !!selection;
  };

  getLastWord = async (context: Word.RequestContext, word: string): Promise<Word.Range> => {
    const docBody = context.document.body;
    await context.sync();
    const searchResults = docBody.search(word.trim(), {
      ignorePunct: true,
      ignoreSpace: true,
      matchCase: false,
      matchWholeWord: true,
    });
    searchResults.load("items");
    await context.sync();
    const range = searchResults.items[searchResults.items.length - 1];
    return range;
  };

  private getLastRange = async (
    context: Word.RequestContext,
    delimiters: string[],
    n: number = 1,
    trimSpacing: boolean = true
  ) => {
    const textRanges = context.document.body.getRange("Whole").getTextRanges(delimiters, trimSpacing);
    textRanges.load("items");
    await context.sync();

    const startIx = textRanges.items.length - n;
    let range = textRanges.items[startIx];
    for (let i = startIx + 1; i < textRanges.items.length; i++) {
      range = range.expandTo(textRanges.items[i]);
    }
    return range;
  };

  shiftFirstCharacterCasing = (text: string) => {
    let firstChar = text.charAt(0);
    if (firstChar === firstChar.toUpperCase()) {
      firstChar = firstChar.toLowerCase();
    }
    if (firstChar === firstChar.toLowerCase()) {
      firstChar = firstChar.toUpperCase();
    }
    return firstChar + text.slice(1);
  };

  processContentCasing = (content: IContent[]) => {
    if (content.length === 0) return content;
    const casedContent = content.map((transcript) => {
      if (this._lowerCase) return { ...transcript, text: transcript.text.toLowerCase() };
      else if (this._upperCase) return { ...transcript, text: transcript.text.toUpperCase() };
      return transcript;
    });

    if (this._shiftCaseNextChar) casedContent[0].text = this.shiftFirstCharacterCasing(casedContent[0].text);
    else if (this._lowerCaseNextWord) casedContent[0].text = casedContent[0].text.toLowerCase();
    else if (this._upperCaseNextWord) casedContent[0].text = casedContent[0].text.toUpperCase();

    return casedContent;
  };

  /***
   * Insertions
   * ! - Clear the selection before inserting (does not apply to some cases)!
   */

  insertText = async (content: IContent[], isFinal: boolean) => {
    content = this.processContentCasing(content);
    const text = transcriptContentToText(content);
    await Word.run(this._interimRange, async (context) => {
      let newRange: Word.Range;
      if (this._interimRange !== null) {
        newRange = this._interimRange.insertText(text, Word.InsertLocation.replace);
      } else {
        newRange = context.document.body.insertText(text, Word.InsertLocation.end);
      }

      // Style the whole text
      newRange.font.bold = this._bold;
      newRange.font.italic = this._italic;
      newRange.font.underline = this._underlined ? Word.UnderlineType.word : Word.UnderlineType.none;

      // Style the first word
      const textRanges = newRange.getTextRanges(WORD_DELIMITERS, true);
      textRanges.load();
      await context.sync();
      // textRanges.items[0] gives us the last word of previous text/sentence (not the first one of the current text)
      // That's why we use ix = textRanges.items.length - wordCount (which results in 0 or 1)
      const wordCount = text.trim().split(" ").length;
      const ix = textRanges.items.length - wordCount;
      if (ix >= 0 && textRanges.items[ix]) {
        textRanges.items[ix].font.bold = this._bold || this._boldNextWord;
        textRanges.items[ix].font.italic = this._italic || this._italicNextWord;
        textRanges.items[ix].font.underline =
          this._underlined || this._underlinedNextWord ? Word.UnderlineType.word : Word.UnderlineType.none;
      }

      if (this._interimRange) this._interimRange.untrack();

      if (isFinal) {
        newRange.font.color = "#000000";
        this._boldNextWord = false;
        this._italicNextWord = false;
        this._underlinedNextWord = false;
        this._upperCaseNextWord = false;
        this._lowerCaseNextWord = false;
        this._shiftCaseNextChar = false;
        this._interimRange = null;
      } else {
        newRange.font.color = "#c4c4c4";
        this._interimRange = newRange;
        this._interimRange.track();
      }
      await context.sync();
    });
  };

  insertNewLine = async () => {
    await Word.run(async (context) => {
      const docBody = context.document.body;
      docBody.insertBreak("Line", Word.InsertLocation.end);
      await context.sync();
    });
  };

  insertNewParagraph = async () => {
    await Word.run(async (context) => {
      const docBody = context.document.body;
      docBody.insertParagraph("", Word.InsertLocation.end);
      await context.sync();
    });
  };

  insertSpace = async () => {
    await Word.run(async (context) => {
      const docBody = context.document.body;
      docBody.insertText(" ", Word.InsertLocation.end);
      await context.sync();
    });
  };

  /***
   * Selections
   */

  selectLastWord = async (word: string) => {
    await Word.run(async (context) => {
      const range = await this.getLastWord(context, word);
      range.select();
    });
  };

  selectLastWords = async (n: number = 1) => {
    await Word.run(async (context) => {
      const range = await this.getLastRange(context, WORD_DELIMITERS, n);
      range.select();
    });
  };

  selectLastSentences = async (n: number = 1) => {
    await Word.run(async (context) => {
      const range = await this.getLastRange(context, SENTENCE_DELIMITERS, n);
      range.select();
    });
  };

  /**
   * Searching
   */

  searchText = async (text: string) => {
    await Word.run(this._searchResults, async (context) => {
      if (this._searchResults) {
        this._searchResults.untrack();
        this._searchResults = null;
      }
      const docBody = context.document.body;
      await context.sync();
      const searchResults = docBody.search(text.trim(), {
        ignorePunct: true,
        ignoreSpace: true,
        matchCase: false,
        matchWholeWord: true,
      });
      searchResults.load("items");
      this._searchResults = searchResults;
      this._searchResults.track();
      await context.sync();
      this._searchMaxIndex = searchResults.items.length - 1;
      this._searchIndex = this._searchMaxIndex;
      const range = searchResults.items[this._searchIndex];
      range.select();
      await context.sync();
    });
  };

  selectNextSearchResult = async () => {
    const newIndex = this._searchIndex + 1;
    this._searchIndex = newIndex > this._searchMaxIndex ? this._searchMaxIndex : newIndex;
    this.selectSearchResult();
  };

  selectPrevSearchResult = async () => {
    const newIndex = this._searchIndex - 1;
    this._searchIndex = newIndex < 0 ? 0 : newIndex;
    this.selectSearchResult();
  };

  selectSearchResult = async () => {
    await Word.run(this._searchResults, async (context) => {
      if (this._searchResults && this._searchIndex !== null) {
        this._searchResults.load("items");
        await context.sync();
        const range = this._searchResults.items[this._searchIndex];
        range.select();
        await context.sync();
      } else {
        console.warn("Search result or index not available!");
      }
    });
  };

  /***
   * Deletions
   */

  // TODO - Handle the deletion of remaining white-spaces
  // TODO - Make it possible to delete punctuation, e.g. when deleteLastWords() is executed on "This is a test.", the remaining text is "This is a test"

  deleteLastWord = async (word: string) => {
    await Word.run(async (context) => {
      const range = await this.getLastWord(context, word);
      range.select();
      range.delete();
    });
  };

  deleteLastNWords = async (n: number = 1) => {
    await Word.run(async (context) => {
      const range = await this.getLastRange(context, WORD_DELIMITERS, n, false);
      range.delete();
    });
  };

  deleteLastNSentences = async (n: number = 1) => {
    await Word.run(async (context) => {
      const range = await this.getLastRange(context, SENTENCE_DELIMITERS, n, false);
      range.delete();
    });
  };

  /***
   * Commands executor
   */

  executeCommand = async (cmd: Command, num: number = 1, text: string = "") => {
    await this.clearCurrentInterim();

    // Check if there is a selection and the command is appropriate to be executed on it
    const cmdExecutedOnSelection = await this.handleSelection(cmd);
    if (cmdExecutedOnSelection) return;

    switch (cmd) {
      case Command.NEW_LINE:
        await this.insertNewLine();
        break;
      case Command.NEW_PARAGRAPH:
        await this.insertNewParagraph();
        break;
      case Command.DELETE_WORD:
        if (text) await this.deleteLastWord(text);
        else await this.deleteLastNWords(num);
        break;
      case Command.DELETE_SENTENCE:
        await this.deleteLastNSentences(num);
        break;
      case Command.SELECT:
        if (text) await this.selectLastWord(text);
        else await this.selectLastWords(num);
        break;
      case Command.BOLD:
        this._boldNextWord = true;
        break;
      case Command.BOLD_ON:
        this._bold = true;
        break;
      case Command.BOLD_OFF:
        this._bold = false;
        break;
      case Command.ITALIC:
        this._italicNextWord = true;
        break;
      case Command.ITALIC_ON:
        this._italic = true;
        break;
      case Command.ITALIC_OFF:
        this._italic = false;
        break;
      case Command.UNDERLINE:
        this._underlinedNextWord = true;
        break;
      case Command.UNDERLINE_ON:
        this._underlined = true;
        break;
      case Command.UNDERLINE_OFF:
        this._underlined = false;
        break;
      case Command.LOWER_CASE:
        this._lowerCaseNextWord = true;
        break;
      case Command.LOWER_CASE_ON:
        this._upperCase = false;
        this._lowerCase = true;
        break;
      case Command.LOWER_CASE_OFF:
        this._lowerCase = false;
        break;
      case Command.SHIFT_CASE_NEXT_CHAR:
        this._shiftCaseNextChar = true;
        break;
      case Command.UPPER_CASE:
        this._upperCaseNextWord = true;
        break;
      case Command.UPPER_CASE_ON:
        this._lowerCase = false;
        this._upperCase = true;
        break;
      case Command.UPPER_CASE_OFF:
        this._upperCase = false;
        break;
      case Command.SPACE:
        await this.insertSpace();
        break;
      case Command.FIND:
        await this.searchText(text);
        break;
      case Command.LEFT:
      case Command.PREV:
        await this.selectPrevSearchResult();
        break;
      case Command.RIGHT:
      case Command.NEXT:
        await this.selectNextSearchResult();
        break;
      case Command.CANCEL:
        await this.cancelSearchAndSelection();
        break;
      default:
        break;
    }
  };
}
