import { isFirefox, isIE, isMobileSafari, isSafari } from "react-device-detect";
import { MicrophoneError } from "_enums/Error";
import audioChunkProcessor from "_utils/AudioChunkProcessor";
import WebWorker from "_utils/WorkerSetup";

// Aliases
(window as any).AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
(window as any).OfflineAudioContext = (window as any).OfflineAudioContext || (window as any).webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor =
  AudioContext.prototype.createScriptProcessor || (AudioContext.prototype as any).createJavaScriptNode;

export default class Microphone {
  private _audioContext: AudioContext;
  private _sourceNode: MediaStreamAudioSourceNode;
  private _scriptNode: ScriptProcessorNode;
  private _worker: Worker;

  private _isInitialized: boolean;

  constructor() {
    this._isInitialized = false;
  }

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  initMicrophone = async (targetSampleRate: number, chunkSize: number, onAudioData: (data: any) => void) => {
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia)
      throw MicrophoneError.AUDIO_NOT_SUPPORTED;

    try {
      const isSafariDevice = isSafari || isIE || isMobileSafari || isFirefox;
      this._audioContext = new window.AudioContext(!isSafariDevice ? { sampleRate: 16000 } : {});
      await this.lockAudio();

      // Select microphone as source node
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      this._sourceNode = this._audioContext.createMediaStreamSource(stream);

      // Create WebWorker which is used to process each audio chunk in separate thread
      // @ts-ignore
      this._worker = new WebWorker(audioChunkProcessor);
      this._worker.addEventListener("message", (e) => {
        onAudioData(e["data"]["audioChunk"]);
      });

      // Create audio processor node to send audio chunks to AudioProcessor (WebWorker) and register callback for processed chunks

      this._scriptNode = this._audioContext.createScriptProcessor(chunkSize, 1, 1);
      this._scriptNode.onaudioprocess = async (event) => {
        if (this._worker) {
          this._worker.postMessage({
            sourceSampleRate: this._audioContext ? this._audioContext.sampleRate : 16000,
            targetSampleRate: targetSampleRate,
            audioChunk: event.inputBuffer.getChannelData(0),
            chunkSize,
          });
        } else {
          console.warn("Web worker is not defined!");
        }
      };

      // Connect source (microphone) to scriptNode
      this._sourceNode.connect(this._scriptNode);
      // Connect script node to destination
      this._scriptNode.connect(this._audioContext.destination);
      this._isInitialized = true;
    } catch (exception) {
      this._isInitialized = false;
      console.table(exception);
      switch (exception.name) {
        case "NotAllowedError":
          throw MicrophoneError.PERMISSION_DENIED;
        case "OverconstrainedError":
          throw MicrophoneError.NOT_FOUND;
        default:
          throw MicrophoneError.DEFAULT;
      }
    }
  };

  unlockAudio = async () => {
    if (!this._audioContext) return;
    await this._audioContext.resume();
  };

  lockAudio = async () => {
    if (!this._audioContext) return;
    await this._audioContext.suspend();
  };

  isRecording = () => {
    return this._audioContext ? this._audioContext.state === "running" : false;
  };
}
