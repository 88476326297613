import { EndPoint } from "_enums/Endpoint";
import { ConfigurationError } from "_enums/Error";
import { ResponseCode } from "_enums/ResponseCode";
import { IConfigurationReceive } from "_interfaces/Configuration";
import { api } from "_services/api";
import { formatError } from "_utils/Api";

/***
 * Executes a get request to get user configuration
 * @returns IUserConfigurationReceive
 * @throws ConfigurationError, if request was not successful
 */
export const fetchConfiguration = async (): Promise<IConfigurationReceive> => {
  try {
    // Make get request to server
    const response = await api({
      method: "GET",
      url: EndPoint.CONFIGURATION,
    });
    return response.data;
  } catch (error) {
    const responseError = formatError(error);
    switch (responseError) {
      case ResponseCode.UNAUTHORIZED:
        throw ConfigurationError.INVALID_CREDENTIALS;
    }
    throw ConfigurationError.DEFAULT;
  }
};
