import { Alert, Modal, ScrollArea, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React from "react";
import { AlertTriangle, InfoCircle } from "tabler-icons-react";
import { sl } from "_assets/localization/sl";
import HelpGroup from "./HelpGroup";

interface Props {
  // height: number;
  isOpened: boolean;
  onClose: () => void;
}

const HelpModal = ({ isOpened, onClose }: Props) => {
  const { height } = useViewportSize();
  return (
    <Modal
      opened={isOpened}
      title={sl.help.help}
      onClose={onClose}
      size="xs"
      centered
      overflow="inside"
      // styles={{
      //   modal: { height: "100%" },
      // }}
    >
      {/* TODO - Fix height */}
      <ScrollArea style={{ height: 600 }}>
        <HelpGroup>
          <Text size="sm">
            Narekovalnik omogoča vstavljanje posebnih znakov in različne operacije nad besedilom. Vse to je mogoče prek
            izgovarjanja posebnih ukazov, navedenih v nadaljevanju.
          </Text>
          <Alert icon={<AlertTriangle size={16} />} title="Nasvet" variant="light" color="orange" radius="lg" my={16}>
            Ukaze izgovarjajte razločno in ločeno od ostalega besedila - pred ukazom naredite kratek premor.
          </Alert>
        </HelpGroup>
        <HelpGroup title={sl.help.inserting} commands={sl.help.commands.inserting} />
        <HelpGroup title={sl.help.selecting} commands={sl.help.commands.selecting}>
          <Alert
            icon={<InfoCircle size={16} />}
            title="Izbrano besedilo"
            variant="light"
            color="blue"
            radius="lg"
            my={16}
          >
            Če je izbran del besedila, se operacije, kot so urejanje in brisanje, izvajajo nad izbranim besedilom.
          </Alert>
        </HelpGroup>
        <HelpGroup title={sl.help.searching} commands={sl.help.commands.searching} />
        <HelpGroup title={sl.help.deleting} commands={sl.help.commands.deleting} />
        <HelpGroup title={sl.help.editing} commands={sl.help.commands.editing} />
        <HelpGroup title={sl.help.editing} commands={sl.help.commands.recording} />
      </ScrollArea>
    </Modal>
  );
};

export default HelpModal;
