export enum Command {
  // DELETE_PARAGRAPH = '<delp>',
  /***
   * STYLING
   */
  BOLD = "<B>", // ✅
  BOLD_OFF = "</b>", // ✅
  BOLD_ON = "<b>", // ✅
  ITALIC = "<I>", // ✅
  ITALIC_OFF = "</i>", // ✅
  ITALIC_ON = "<i>", // ✅
  UNDERLINE = "<U>", // ✅
  UNDERLINE_OFF = "</u>", // ✅
  UNDERLINE_ON = "<u>", // ✅
  SHIFT_CASE_NEXT_CHAR = "<cc>", // ✅
  UPPER_CASE = "<UC>", // ✅
  UPPER_CASE_OFF = "</uc>", // ✅
  UPPER_CASE_ON = "<uc>", // ✅
  LOWER_CASE = "<LC>", // ✅
  LOWER_CASE_OFF = "</lc>", // ✅
  LOWER_CASE_ON = "<lc>", // ✅

  /***
   * INSERTION
   */
  NEW_LINE = "<nl>", // ✅
  NEW_PARAGRAPH = "<np>", // ✅
  // SPACE = "<spc>", // TODO - should be this, but is " "
  SPACE = " ", // ✅

  /**
   * MANIPULATION
   */
  DELETE_SENTENCE = "<dels>", // ✅ // ? - Also supports "<dels> <number>"
  DELETE_WORD = "<delw>", // ✅ // ? - Also supports "<delw> <number>"

  /***
   * SEARCHING
   */
  FIND = "<find>", // ✅
  LEFT = "<left>", // ✅
  RIGHT = "<right>", // ✅
  NEXT = "<next>", // ✅
  PREV = "<prev>", // ✅

  /***
   * SELECTION
   */
  SELECT = "<select>", // ✅
  CANCEL = "<canc>", // ✅ // ? - Currently cancels search or selection

  /***
   * Ending dictation
   */
  END = "<end>", // ✅

  /***
   * NOT RELEVANT / TO BE DETERMINED, HOW TO SUPPORT
   */
  CONFIRM = "<ok>",
  CORRECT = "<corr>",
  INSERT = "<ins>",
  INSERT_AFTER = "<insa>",
  INSERT_BEFORE = "<insb>",
  REPLACE = "<repl>",
  SHOW_LESS = "<less>",
  SHOW_MORE = "<more>",
  SPELL = "<spell>",
}
