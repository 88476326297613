import { EndPoint } from "_enums/Endpoint";
import { Token } from "_interfaces/Credentials";
import { formatError, getExpiryTimestamp } from "_utils/Api";
import { ResponseCode } from "_enums/ResponseCode";
import { RefreshError } from "_enums/Error";
import { authApi } from "_services/api";
import qs from "qs";
import { CLIENT_ID } from "_constants/env";

/***
 * Executes a post request to refresh the access token
 * @param refreshToken Refresh token, that should be valid (otherwise the request will fail)
 * @returns The refreshed access token (Token)
 * @throws RefreshError, if request was not successful
 */
export const refreshCredentials = async (refreshToken: Token): Promise<Token> => {
  // Prepare request data
  const data = qs.stringify({
    client_id: CLIENT_ID,
    grant_type: "refresh_token",
    refresh_token: refreshToken.token,
  });

  try {
    // Make post request to server
    const response = await authApi({
      method: "POST",
      url: EndPoint.AUTH,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    });
    // Parse access token
    const accessToken: Token = {
      token: response.data.access_token,
      expiryTimestamp: getExpiryTimestamp(response.data.expires_in),
    };
    return accessToken;
  } catch (error) {
    const responseError = formatError(error);
    switch (responseError) {
      case ResponseCode.UNAUTHORIZED:
        throw RefreshError.INVALID_TOKEN;
    }
    throw RefreshError.DEFAULT;
  }
};
