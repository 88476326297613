import { IContent } from "_interfaces/Transcript";

export const isPunctuation = (text: string) => text.match(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g);

export const transcriptContentToText = (content: IContent[]) => {
  let text = "";
  content.forEach((transcript) => {
    text += (transcript.spaceBefore ? " " : "") + transcript.text;
  });
  return text;
};
