import { Center, Image, Loader, Stack } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React from "react";

const SplashScreen = () => {
  const { height, width } = useViewportSize();
  return (
    <Center style={{ width, height }}>
      <Stack align="center" spacing="xl">
        <Image src={require("_assets/images/img_logo.png")} width={200} fit="contain" alt="VITASIS" />
        <Loader color="dark" variant="dots" />
      </Stack>
    </Center>
  );
};

export default SplashScreen;
