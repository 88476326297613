import { EndPoint } from "_enums/Endpoint";
import { Credentials } from "_interfaces/Credentials";
import { formatError, getExpiryTimestamp } from "_utils/Api";
import { ResponseCode } from "_enums/ResponseCode";
import { LoginError } from "_enums/Error";
import { authApi } from "_services/api";
import qs from "qs";
import { CLIENT_ID } from "_constants/env";

/***
 * Executes a post request to login
 * @param username User's username
 * @param password User's password
 * @returns Credentials
 * @throws LoginError, if request was not successful
 */
export const loginUser = async (username: string, password: string): Promise<Credentials> => {
  // Prepare request data
  const data = qs.stringify({
    client_id: CLIENT_ID,
    grant_type: "password",
    username: username,
    password: password,
  });

  try {
    // Make post request to server
    const response = await authApi({
      method: "POST",
      url: EndPoint.AUTH,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    });
    // Parse user credentials
    const credentials: Credentials = {
      accessToken: {
        token: response.data.access_token,
        expiryTimestamp: getExpiryTimestamp(response.data.expires_in),
      },
      refreshToken: {
        token: response.data.refresh_token,
        expiryTimestamp: getExpiryTimestamp(response.data.refresh_expires_in),
      },
    };
    return credentials;
  } catch (error) {
    const responseError = formatError(error);
    switch (responseError) {
      case ResponseCode.UNAUTHORIZED:
        throw LoginError.INVALID_DATA;
    }
    throw LoginError.DEFAULT;
  }
};
