export enum CommonError {
  DEFAULT = "Something went wrong.",
  NETWORK = "Network is not reachable.",
  CREDENTIALS = "No credentials saved!",
}

export enum LoginError {
  DEFAULT = "Login failed!",
  INVALID_DATA = "Login failed! Invalid login data.",
  STORAGE = "Login failed! Could not store credentials.",
}

export enum LogoutError {
  DEFAULT = "Logout failed!",
}

export enum RefreshError {
  DEFAULT = "Credentials refresh failed!",
  INVALID_TOKEN = "Credentials refresh failed! Refresh token expired / invalid.",
}

export enum SessionError {
  DEFAULT = "Truebar session failed!",
  INVALID_CREDENTIALS = "Truebar session failed! Credentials not found or invalid!",
}

export enum ConfigurationError {
  DEFAULT = "Could not get/set configuration!",
  INVALID_CREDENTIALS = "Could not get/set configuration! Credentials not found or invalid!",
}

export enum MicrophoneError {
  DEFAULT = "Microphone is not accessible.",
  AUDIO_NOT_SUPPORTED = "Your browser does not support WebAudio.",
  PERMISSION_DENIED = "Microphone permission is denied.",
  NOT_FOUND = "No appropriate microphone was found.",
}
