import React, { useContext } from "react";
import { AppContext } from "_contexts/App";

const useAuth = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw "useAuth can only be used in a component within AppProvider.";
  }
  return context.auth;
};

export default useAuth;
