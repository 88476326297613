import { Environment } from "_enums/Environment";

const environments = {
  [Environment.PRODUCTION]: {
    BASE_URL: "https://demo-api.true-bar.si/api",
    BASE_WS_URL: "wss://demo-api.true-bar.si/ws",
    BASE_KC_URL: "https://demo-auth.true-bar.si",
  },
  [Environment.STAGING]: {
    BASE_URL: "https://staging-api.true-bar.si/api",
    BASE_WS_URL: "wss://staging-api.true-bar.si/ws",
    BASE_KC_URL: "https://staging-auth.true-bar.si",
  },
  [Environment.DEVELOPMENT]: {
    BASE_URL: "https://dev-editor-backend.true-bar.si/api",
    BASE_WS_URL: "wss://dev-editor-backend.true-bar.si/ws",
    BASE_KC_URL: "https://dev-keycloak.vitasis.si",
  },
};

const CLIENT_ID = "truebar-client";

export { environments, CLIENT_ID };
