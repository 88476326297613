import { Button, Modal, Stack, Stepper, Text } from "@mantine/core";
import React from "react";
import { sl } from "_assets/localization/sl";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const OnboardingModal = ({ isOpened, onClose }: Props) => {
  return (
    <Modal opened={isOpened} title={sl.onboarding.welcome} onClose={onClose} size="xs" centered>
      <Stack spacing={32}>
        <Text>{sl.onboarding.intro}</Text>
        <Stepper
          active={-1}
          orientation="vertical"
          styles={{
            stepDescription: {
              lineHeight: "1.5",
            },
          }}
        >
          <Stepper.Step label={sl.onboarding.registerTitle} description={sl.onboarding.registerDescription} />
          <Stepper.Step label={sl.onboarding.loginTitle} description={sl.onboarding.loginDescription} />
          <Stepper.Step label={sl.onboarding.dictationTitle} description={sl.onboarding.dictationDescription} />
        </Stepper>
        <Button onClick={onClose}>{sl.onboarding.start}</Button>
      </Stack>
    </Modal>
  );
};
