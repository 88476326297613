import React, { useContext } from "react";
import { AppContext } from "_contexts/App";

const useFirstRun = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw "useFirstRun can only be used in a component within AppProvider.";
  }
  return context.isFirstRun;
};

export default useFirstRun;
