import { useRef } from "react";
import { EndPoint } from "_enums/Endpoint";
import useWs from "_hooks/useWs";
import { IConfigurationReceive, IConfigurationSend } from "_interfaces/Configuration";
import { IMessage } from "_interfaces/Transcript";
import { fetchConfiguration } from "_services/api/fetchConfiguration";
import { patchConfiguration } from "_services/api/patchConfiguration";
import { CredentialsManager } from "_services/CredentialsManager";
import Microphone from "_services/Microphone";

interface UseTruebarReturn {
  startRecognition: () => Promise<void>;
  pauseRecognition: () => Promise<void>;
  stopRecognition: () => Promise<void>;
  sendAudioChunk: (audioChunk: ArrayBuffer) => void;
  getConfig: () => Promise<IConfigurationReceive>;
  setConfig: (config: IConfigurationSend) => Promise<void>;
  // getRecordingUrl: () => Promise<string | null>;
}

const useTruebar = (onMessage: (message: IMessage) => void): UseTruebarReturn => {
  const microphone = useRef(new Microphone());
  const ws = useWs();

  const sendAudioChunk = (audioChunk: ArrayBuffer) => {
    try {
      ws.sendData(audioChunk);
    } catch (error) {
      console.log(error);
    }
  };

  const startRecognition = async () => {
    if (!microphone.current.isInitialized) {
      await microphone.current.initMicrophone(16000, 4096, (data) => sendAudioChunk(data));
    }
    await ws.openSession(onMessage);
    await microphone.current.unlockAudio();
  };

  const pauseRecognition = async () => {
    await microphone.current.lockAudio();
    await ws.closeSession();
  };

  const stopRecognition = async () => {
    await microphone.current.lockAudio();
    await ws.closeSession(false);
  };

  const getConfig = async (): Promise<IConfigurationReceive> => {
    return await fetchConfiguration();
  };

  const setConfig = async (config: IConfigurationSend): Promise<void> => {
    await patchConfiguration(config);
    console.log("Config set!");
  };

  // const getRecordingUrl = async (): Promise<string | null> => {
  //   const sessionInfo = ws.getSessionInfo();
  //   if (sessionInfo === null) return null;
  //   const accessToken = (await CredentialsManager.getValidCredentials()).accessToken.token;
  //   return `${environments[active].BASE_URL}${EndPoint.SESSIONS}/${sessionInfo.sessionId}/audio.wav?access_token=${accessToken}`; // TODO
  // };

  return {
    startRecognition,
    pauseRecognition,
    stopRecognition,
    sendAudioChunk,
    getConfig,
    setConfig,
    // getRecordingUrl
  };
};

export default useTruebar;
