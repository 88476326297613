import * as React from "react";
import DictationScreen from "_screens/DictationScreen";
import LoginScreen from "_screens/LoginScreen";
import useAuth from "_hooks/useAuth";
import SplashScreen from "_screens/SplashScreen";

/* global Word, require */

interface Props {
  isOfficeInitialized: boolean;
}

const App = ({ isOfficeInitialized }: Props) => {
  const { isSignedIn, isLoading } = useAuth();

  if (!isOfficeInitialized || isLoading) {
    return <SplashScreen />;
  }

  if (!isSignedIn) {
    return <LoginScreen />;
  }

  return <DictationScreen />;
};

export default App;
