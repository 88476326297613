import React, { useContext } from "react";
import { AppContext } from "_contexts/App";

export function useEnvironment() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useEnvironment must be used within AppProvider");
  }
  return context.environment;
}
