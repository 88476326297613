import { Localization } from "_interfaces/Localization";

export const APP_NAME = "Truebar MS Word Add-in";
export const COMPANY_NAME = "Vitasis";

/***
 * Slovenian
 */
export const sl: Localization = {
  general: {
    cancel: "Prekliči",
    continue: "Nadaljuj",
    discard: "Zavrzi",
    next: "Naprej",
    send: "Pošlji",
    tryAgain: "Poskusi znova",
    search: "Iskanje",
    delete: "Izbriši",
    save: "Shrani",
    error: "Napaka",
  },
  onboarding: {
    welcome: "Pozdravljeni!",
    intro: "Tu je nekaj korakov za lažji začetek uporabe vtičnika Truebar.",
    registerTitle: "Registracija",
    registerDescription: "Če še nimate računa, nam pošljite e-pošto na info@vitasis.si.",
    loginTitle: "Prijava",
    loginDescription: "Prijavite se z vašim Truebar računom.",
    dictationTitle: "Narekovanje",
    dictationDescription: "Enostavno narekujte besedilo v slovenskem jeziku!",
    start: "Pa začnimo!",
  },
  login: {
    login: "Prijava",
    server: "Strežnik",
    activeEnvSuccessTitle: "Strežnik nastavljen",
    activeEnvSuccessMessage: "Strežnik je bil uspešno nastavljen na:",
    activeEnvFailureMessage: "Strežnika ni bilo mogoče nastaviti. Prosimo, poskusite kasneje.",
    username: "Uporabniško ime",
    password: "Geslo",
    loginDataInvalid: "Uporabniško ime ali geslo je napačno.",
    requestAccess: "Zaprosi za dostop",
    requestAccessSubject: "Prosim za dostop do aplikacije Truebar MS Word Add-in",
    loginFailedTitle: "Prijava ni uspela",
    loginFailedDescription: "Preverite vpisano uporabniško ime in geslo.",
    copyright: "Vitasis d.o.o. © 2022",
  },
  logout: {
    logout: "Odjava",
    logmeout: "Odjavi me",
    logoutMessage: "Ali ste prepričani, da želite se odjaviti?",
  },
  settings: {
    settings: "Nastavitve",
    textDomain: "Vrsta besedila",
    noOptions: "Ni možnosti",
    autoPunctuation: "Samodejno postavljanje ločil",
    settingsSaveSuccess: "Nastavitve so shranjene.",
    settingsSaveFailure: "Shranjevanje nastavitev ni uspelo. Prosimo, poskusite kasneje.",
    settingsFetchFailed: "Pridobivanje trenutnih nastavitev ni uspelo. Prosimo, poskusite kasneje.",
  },
  domains: {
    generalTitle: "Splošno",
    generalDescription: "Priporočeno za večino besedil",
    lawTitle: "Pravno",
    lawDescription: "Primerno za pravna besedila",
    medicalTitle: "Medicinsko",
    medicalDescription: "Primerno za medicinska besedila",
  },
  dictation: {
    instruction: "Pritisnite mikrofon za narek",
    listening: "Poslušam ...",
    endingRecording: "Končujem snemanje ...",
    commandDetected: "Zaznan ukaz",
  },
  help: {
    help: "Pomoč",
    command: "Ukaz",
    explanation: "Razlaga",
    general: "Splošno",
    inserting: "Vstavljanje",
    selecting: "Izbiranje",
    searching: "Iskanje",
    deleting: "Brisanje",
    editing: "Urejanje",
    recording: "Snemanje",
    commands: {
      inserting: [
        { command: "nova vrstica", explanation: "vstavi novo vrstico" },
        { command: "nov odstavek", explanation: "vstavi nov odstavek" },
        { command: "pika, vejica, presledek itd.", explanation: "vstavi podano ločilo" },
      ],
      selecting: [
        { command: "izberi", explanation: "izbere zadnjo besedo" },
        { command: "izberi [število N]", explanation: "izbere zadnjih N besed" },
        {
          command: "izberi [beseda / besedna zveza]",
          explanation: "izbere podano besedo ali besedno zvezo (zadnjo pojavitev)",
        },
        { command: "prekliči", explanation: "prekliče izbiro" },
      ],
      searching: [
        {
          command: "išči [beseda / besedna zveza]",
          explanation: "poišče in izbere podano besedo ali besedno zvezo (zadnjo pojavitev)",
        },
        {
          command: "prejšnja, levo",
          explanation: "če je iskanje aktivno, označi prejšnjo pojavitev iskane besede ali besedne zveze",
        },
        {
          command: "naslednja, desno",
          explanation: "če je iskanje aktivno, označi naslednjo pojavitev iskane besede ali besedne zveze",
        },
        { command: "prekliči", explanation: "prekliče iskanje in izbiro" },
      ],
      deleting: [
        { command: "briši", explanation: "izbriše zadnjo ali izbrano besedo" },
        { command: "briši [število N]", explanation: "izbriše zadnjih N besed" },
        {
          command: "briši [beseda / besedna zveza]",
          explanation: "izbriše zadnjo podano besedo ali besedno zvezo (zadnjo pojavitev)",
        },
        { command: "briši stavek", explanation: "izbriše zadnji stavek" },
        { command: "briši stavek [število N]", explanation: "izbriše zadnjih N stavkov" },
      ],
      editing: [
        {
          command: "odebeljeno, bold",
          explanation: "odebelji izbiro ali naslednjo izgovorjeno besedo",
        },
        { command: "poševno, italik", explanation: "izbiri ali naslednji izgovorjeni besedi doda poševen slog" },
        {
          command: "podčrtano",
          explanation: "podčrta izbiro ali naslednjo izgovorjeno besedo",
        },
        {
          command: "vključi/izključi odebeljeno/bold",
          explanation: "vključi/izključi zapisovanje z odebeljenim slogom",
        },
        { command: "vključi/izključi poševno/italik", explanation: "vključi/izključi zapisovanje s poševnim slogom" },
        { command: "vključi/izključi podčrtano", explanation: "vključi/izključi zapisovanje s podčrtanim slogom" },
        { command: "z veliko", explanation: "izbiro ali naslednjo izgovorjeno besedo zapiše z velikimi črkami" },
        { command: "z malo", explanation: "izbiro ali naslednjo izgovorjeno besedo zapiše z malimi črkami" },
        { command: "vključi/izključi velike črke", explanation: "vključi/izključi zapisovanje z velikimi črkami" },
        { command: "vključi/izključi male črke", explanation: "vključi/izključi zapisovanje z malimi črkami" },
        { command: "dvigalka", explanation: "naslednji izgovorjeni besedi zamenja prvo črko v malo/veliko" },
      ],
      recording: [{ command: "zaključi snemanje", explanation: "ustavi snemanje nareka" }],
    },
  },
  error: {
    default: "Nekaj je šlo narobe. Prosimo, poskusite kasneje.",
    noInternet: "Preverite povezavo z internetom.",
    noInternetTitle: "Ni povezave",
    sessionTimeout: "Seja je potekla. Prosimo, ponovno se prijavite.",
    micNoPermission: "Preverite dovoljenje za mikrofon.",
    micNotFound: "Mikrofon ni priključen ali nastavljen v sistemu.",
    audioNotSupported: "Snemanje ni podprto na vašem sistemu.",
  },
  command: {
    newLine: "nova vrstica",
    newParagraph: "nov odstavek",
    boldNextWord: "odebeljeno",
    boldOff: "izključi odebeljeno",
    boldOn: "vključi odebeljeno",
    italicNextWord: "ležeče",
    italicOff: "izključi ležeče",
    italicOn: "vključi ležeče",
    underlineNextWord: "podčrtano",
    underlineOff: "izključi podčrtano",
    underlineOn: "vključi podčrtano",
    shiftCaseNextChar: "dvigalka",
    upperCaseNextWord: "z velikimi črkami",
    upperCaseOff: "izključi velike črke",
    upperCaseOn: "vključi velike črke",
    lowerCaseNextWord: "z malimi črkami",
    lowerCaseOff: "izključi male črke",
    lowerCaseOn: "vključi male črke",
    deleteSentence: "izbriši stavek",
    deleteWord: "izbriši besedo",
    select: "izberi",
    space: "presledek",
    find: "išči",
    prev: "prejšnji / levo",
    next: "naslednji / desno",
    cancel: "prekliči",
    end: "konec snemanja",
  },
};
