import { Environment } from "_enums/Environment";
import { Key } from "_enums/Key";

/***
 * Manager for getting and storing the current Environment in local storage.
 */

export class EnvManager {
  /***
   * Get the current active Environment from local storage.
   * @returns {Environment} The current Environment or null if none is stored.
   */
  static getActiveEnv = (): Environment | null => {
    try {
      const activeEnvSerialized = localStorage.getItem(Key.ACTIVE_ENV);
      if (activeEnvSerialized) {
        const activeEnv: Environment = JSON.parse(activeEnvSerialized);
        console.log("Active environment successfully loaded from storage.");
        return activeEnv;
      }
    } catch (error) {
      console.error("Active environment was not found:", error);
    }
    return null;
  };

  /***
   * Store a new active Environment in local storage.
   */
  static storeActiveEnv = async (env: Environment): Promise<void> => {
    try {
      localStorage.setItem(Key.ACTIVE_ENV, JSON.stringify(env));
      console.log("Active environment successfully stored.");
    } catch (error) {
      console.error("Could not store active environment:", error);
    }
  };
}
