import { showNotification, hideNotification } from "@mantine/notifications";
import React, { createContext, useEffect, useState } from "react";
import { sl } from "_assets/localization/sl";
import { Environment } from "_enums/Environment";
import { CommonError } from "_enums/Error";
import { Key } from "_enums/Key";
import useOnline from "_hooks/useOnline";
import { Credentials } from "_interfaces/Credentials";
import { setApiURLs } from "_services/api";
import { loginUser } from "_services/api/loginUser";
import { CredentialsManager } from "_services/CredentialsManager";
import { EnvManager } from "_services/EnvManager";

interface AppContextData {
  isFirstRun: boolean;
  environment: [activeEnv: Environment | null, updateActiveEnv: (env: Environment) => void];
  auth: {
    isLoading: boolean;
    isSignedIn: boolean;
    credentials: Credentials | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
  };
}

const OFFLINE_NOTIFICATION_ID = "msword-app-offline";

export const AppContext = createContext({} as AppContextData);

const AppProvider = ({ children }) => {
  const { isOnline } = useOnline();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [credentials, setCredentials] = useState<Credentials | null>(null);
  const [isFirstRun, setIsFirstRun] = useState<boolean>(false);
  const [activeEnv, setActiveEnv] = useState<Environment | null>(null);

  /***
   * "Listen" if the app is online
   */
  useEffect(() => {
    if (!isOnline) {
      showNotification({
        id: OFFLINE_NOTIFICATION_ID,
        title: sl.error.noInternetTitle,
        message: sl.error.noInternet,
        color: "red",
        autoClose: false,
      });
    } else {
      hideNotification(OFFLINE_NOTIFICATION_ID);
    }
  }, [isOnline]);

  /***
   * Loads the credentials, environment and check first run on start of the app
   */
  useEffect(() => {
    loadActiveEnv();
    loadCredentials();
    checkFirstRun();
  }, []);

  const loadActiveEnv = () => {
    try {
      const env: Environment | null = EnvManager.getActiveEnv();
      if (env) {
        const success = setApiURLs(env);
        if (!success) {
          showNotification({
            title: sl.general.error,
            message: sl.login.activeEnvFailureMessage,
            color: "red",
          });
          return;
        }
        setActiveEnv(env);
      } else {
        updateActiveEnv(Environment.PRODUCTION);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateActiveEnv = async (env: Environment): Promise<void> => {
    try {
      if (env === activeEnv) return;
      await EnvManager.storeActiveEnv(env);
      const success = setApiURLs(env);
      if (success) {
        setActiveEnv(env);
        showNotification({
          title: sl.login.activeEnvSuccessTitle,
          message: sl.login.activeEnvSuccessMessage + " " + env,
          color: "green",
        });
      } else {
        showNotification({
          title: sl.general.error,
          message: sl.login.activeEnvFailureMessage,
          color: "red",
        });
      }
    } catch (error) {
      showNotification({
        title: sl.general.error,
        message: sl.login.activeEnvFailureMessage,
        color: "red",
      });
      console.log(error);
    }
  };

  const checkFirstRun = () => {
    const firstRun = localStorage.getItem(Key.FIRST_RUN);
    if (firstRun !== null) {
      localStorage.setItem(Key.FIRST_RUN, "false");
      setIsFirstRun(false);
    } else {
      localStorage.setItem(Key.FIRST_RUN, "true");
      setIsFirstRun(true);
    }
  };

  /***
   * Loads the user credentials
   */
  const loadCredentials = async (): Promise<void> => {
    const credentials: Credentials | null = await CredentialsManager.getValidCredentials();
    setCredentials(credentials);
    setTimeout(() => {
      setIsLoading(false);
    }, 750);
  };

  /***
   * Attempts login request and saves credentials
   * @returns user credentials
   * @throws LoginError, if there was an error
   */
  const login = async (username: string, password: string) => {
    if (!navigator.onLine) {
      throw CommonError.NETWORK;
    }
    try {
      const credentials = await loginUser(username, password);
      CredentialsManager.storeCredentials(credentials.accessToken, credentials.refreshToken);
      setCredentials(credentials);
    } catch (error) {
      throw error;
    }
  };

  /***
   * Clears user credentials, logs user out
   * @throws LogoutError, if there was an error deleting from Secure Store
   */
  const logout = async () => {
    CredentialsManager.clearCredentials();
    setCredentials(null);
  };

  return (
    <AppContext.Provider
      value={{
        isFirstRun,
        environment: [activeEnv, updateActiveEnv],
        auth: {
          isLoading,
          isSignedIn: !!credentials,
          credentials,
          login,
          logout,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
