import { Accordion, ActionIcon, Alert, Button, Container, Group, Loader, Stack, Text, Title } from "@mantine/core";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { InfoCircle, Logout, Microphone, Settings } from "tabler-icons-react";
import { getTranslationForCommand } from "_assets/localization/mappers";
import { sl } from "_assets/localization/sl";
import HelpModal from "_components/HelpModal";
import { LogoutModal } from "_components/LogoutModal";
import { SettingsModal } from "_components/SettingsModal";
import { Command } from "_enums/Command";
import { MicrophoneError, SessionError } from "_enums/Error";
import useAuth from "_hooks/useAuth";
import useTruebar from "_hooks/useTruebar";
import { IContent, IMessage } from "_interfaces/Transcript";
import WordManager from "_services/WordManager";
import { transcriptContentToText } from "_utils/Transcript";

interface CommandMessage {
  message: string;
  command: string;
  option?: string;
}

const DictationScreen = () => {
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [helpOpened, setHelpOpened] = useState(false);
  const [logoutOpened, setLogoutOpened] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingLoading, setIsRecordingLoading] = useState<boolean>(false);

  const [message, setMessage] = useState<string>(sl.dictation.instruction);
  const [commandMessage, setCommandMessage] = useState<CommandMessage | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { logout } = useAuth();

  const wordManager = useRef(new WordManager());

  const controls = useAnimation();
  const alertControls = useAnimation();

  const onMessage = async (message: IMessage) => {
    const content: IContent[] = JSON.parse(message.transcript.content);
    console.table(content);
    const firstWord = content[0].text;
    let num = 1;
    let text = "";
    if (Object.values(Command).includes(firstWord as Command)) {
      if (message.isFinal) {
        if (firstWord === Command.END) {
          await wordManager.current.clearCurrentInterim();
          await stopRecording();
        } else {
          if (content.length > 1) {
            const secondWord = content[1].text;
            if (!isNaN(Number(secondWord))) {
              num = Number(secondWord);
            } else {
              text = transcriptContentToText(content.slice(1));
            }
          }
          setCommandMessage({
            message: sl.dictation.commandDetected,
            command: getTranslationForCommand(firstWord as Command, sl),
            option: text ? text.toLowerCase() : num !== 1 ? num.toString() : undefined,
          });
          setTimeout(() => {
            setCommandMessage(null);
          }, 2000);
          await wordManager.current.executeCommand(firstWord as Command, num, text);
        }
      }
    } else {
      await wordManager.current.insertText(content, message.isFinal);
    }
  };

  const truebar = useTruebar(onMessage);

  const stopRecording = async () => {
    try {
      setMessage(sl.dictation.endingRecording);
      setIsRecordingLoading(true);
      await truebar.pauseRecognition();
      setIsRecording(false);
    } catch (error) {
      console.log(error);
    }
  };

  const startRecording = async () => {
    try {
      setIsRecordingLoading(true);
      await truebar.startRecognition();
      setErrorMessage(null);
      setIsRecording(true);
    } catch (error) {
      setIsRecordingLoading(false);
      switch (error) {
        case MicrophoneError.PERMISSION_DENIED:
          setErrorMessage(sl.error.micNoPermission);
          break;
        case MicrophoneError.NOT_FOUND:
          setErrorMessage(sl.error.micNotFound);
          break;
        case MicrophoneError.AUDIO_NOT_SUPPORTED:
          setErrorMessage(sl.error.audioNotSupported);
          break;
        case SessionError.INVALID_CREDENTIALS:
          setErrorMessage(sl.error.sessionTimeout);
          setTimeout(async () => {
            await logout();
          }, 2000);
          break;
        default:
          setErrorMessage(sl.error.default);
          break;
      }
      console.log(error);
    }
  };

  const handleToggleRecording = async () => {
    if (isRecording) {
      await stopRecording();
    } else {
      await startRecording();
    }
  };

  useEffect(() => {
    setIsRecordingLoading(false);
    if (isRecording) {
      controls.start({
        scale: [1, 0.95, 1.05],
      });
      setMessage(sl.dictation.listening);
    } else {
      controls.set({ scale: 1 });
      controls.stop();
      setMessage(sl.dictation.instruction);
    }
  }, [isRecording]);

  useEffect(() => {
    if (commandMessage) {
      alertControls.start({
        scale: [1, 1.05, 1],
      });
    }
  }, [commandMessage]);

  const handleLogout = async () => {
    if (isRecording) {
      await stopRecording();
    }
    setLogoutOpened(true);
  };

  return (
    <>
      <Stack
        sx={() => ({
          height: "95%", // TODO
        })}
        justify="center"
        align="center"
        p={16}
      >
        <Container style={{ display: "flex", flex: 0.75, alignItems: "flex-end" }}>
          {errorMessage && (
            <Alert color={"red"} radius="lg">
              <Text align="center" color="red" size="md" weight={500}>
                {errorMessage}
              </Text>
            </Alert>
          )}
          {!errorMessage && (
            <motion.div animate={alertControls} transition={{ duration: 0.5 }}>
              <Alert color={"blue"} radius="lg">
                {!commandMessage && (
                  <Text align="center" color="blue" size="md" weight={500}>
                    {message}
                  </Text>
                )}
                {commandMessage && (
                  <Text align="center" color="blue" size="md">
                    <Text weight={500}>{commandMessage.message}</Text>
                    <Text weight={700}>
                      {`'${commandMessage.command}${commandMessage.option ? " " + commandMessage.option : ""}'`}
                    </Text>
                  </Text>
                )}
              </Alert>
            </motion.div>
          )}
        </Container>
        <Container style={{ display: "flex", flex: 1, alignItems: "flex-start" }}>
          <Group position="apart">
            <IconButton onClick={() => setSettingsOpened(true)}>
              <Settings size={28} />
            </IconButton>
            <motion.div animate={controls} transition={{ repeat: Infinity, duration: 1, repeatType: "reverse" }}>
              <ActionIcon
                onClick={handleToggleRecording}
                variant={isRecording ? "filled" : "outline"}
                color="blue"
                sx={{ width: 124, height: 124, borderRadius: 62, borderWidth: 3 }}
              >
                {isRecordingLoading && <Loader size={24} color={isRecording ? "white" : undefined} />}
                {!isRecordingLoading && <Microphone size={48} />}
              </ActionIcon>
            </motion.div>
            <IconButton onClick={() => setHelpOpened(true)}>
              <InfoCircle size={28} />
            </IconButton>
          </Group>
        </Container>
        <Button leftIcon={<Logout size={14} />} variant="subtle" color="red" onClick={handleLogout}>
          {sl.logout.logout}
        </Button>
      </Stack>
      <SettingsModal isOpened={settingsOpened} onClose={() => setSettingsOpened(false)} />
      <HelpModal isOpened={helpOpened} onClose={() => setHelpOpened(false)} />
      <LogoutModal isOpened={logoutOpened} onClose={() => setLogoutOpened(false)} />
    </>
  );
};

export default DictationScreen;

const IconButton = ({ children, onClick }) => {
  return (
    <ActionIcon variant="transparent" sx={{ width: 42, height: 42, borderRadius: 21 }} onClick={onClick}>
      {children}
    </ActionIcon>
  );
};
