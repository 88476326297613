import App from "_taskpane/App";
import { AppContainer } from "react-hot-loader";
// import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import AppProvider from "_contexts/App";
import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";

/* global document, Office, module, require */

// initializeIcons();

let isOfficeInitialized = false;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <AppProvider>
        <MantineProvider withNormalizeCSS withGlobalStyles>
          <NotificationsProvider position="top-center">
            <Component isOfficeInitialized={isOfficeInitialized} />
          </NotificationsProvider>
        </MantineProvider>
      </AppProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
