import axios from 'axios';

export const getExpiryTimestamp = (durationInSeconds: number) =>
  Date.now() + durationInSeconds * 1000;

export const isTokenValid = (expiryTimestamp: number) =>
  Date.now() < expiryTimestamp;

/***
 * If @param error is an @type AxiosError, it @returns response code, else just the original error
 * It also logs a formatted error message
 */
export const formatError = (error: any): number | any => {
  if (axios.isAxiosError(error) && error.response) {
    const responseCode: number = error.response.status;
    console.log('------- REQUEST FAILED -------');
    console.log('Response code:', responseCode);
    console.log('Headers:', error.response.headers);
    console.log('Data:', error.response.data);
    console.log('------------------------------');
    // Return response code as error
    return responseCode;
  } else {
    console.log('------- REQUEST FAILED -------');
    console.log(error);
    console.log('------------------------------');
    return error;
  }
};
