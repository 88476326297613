import { Container, Table, Title } from "@mantine/core";
import React from "react";
import { sl } from "_assets/localization/sl";
import { HelpCommand } from "_interfaces/Localization";

interface Props {
  title?: string;
  commands?: HelpCommand[];
  children?: React.ReactNode;
}

const HelpGroup = ({ title, commands, children }: Props) => {
  const rows = commands?.map((cmd) => (
    <tr key={cmd.command}>
      <td>
        <i>{cmd.command}</i>
      </td>
      <td>{cmd.explanation}</td>
    </tr>
  ));

  return (
    <Container px={0}>
      {title && (
        <Title order={5} my={8}>
          {title}
        </Title>
      )}
      {commands && (
        <Table>
          <thead>
            <tr>
              <th>{sl.help.command}</th>
              <th>{sl.help.explanation}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {children}
    </Container>
  );
};

export default HelpGroup;
