import { environments } from "_constants/env";
import { Credentials } from "_interfaces/Credentials";
import { CredentialsManager } from "_services/CredentialsManager";
import axios, { AxiosRequestConfig } from "axios";
import { Environment } from "_enums/Environment";

/***
 * Axios instance for all the API requests, except authentication.
 */
export const api = axios.create({
  // baseURL: environments[active].BASE_URL,
});

/***
 * Axios instance for authentication requests (login, refresh token).
 */
export const authApi = axios.create({
  // baseURL: environments[active].BASE_KC_URL,
});

/***
 * Sets the base URL of the axios API instance, according to the Environment given.
 * @return Boolean indicating, if the base URL was set successfully.
 */
export const setApiURLs = (env: Environment): boolean => {
  if (!environments[env].BASE_URL || !environments[env].BASE_KC_URL) {
    console.error("Could not set environment URLs! They do not exist!");
    return false;
  }
  api.defaults.baseURL = environments[env].BASE_URL;
  authApi.defaults.baseURL = environments[env].BASE_KC_URL;
  return true;
};

/***
 * Interceptor that adds authorization header to all requests, made via api service.
 */
api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const credentials: Credentials | null = await CredentialsManager.getValidCredentials();
    if (credentials && config.headers) {
      config.headers.Authorization = `Bearer ${credentials.accessToken.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
