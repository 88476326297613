export enum TranscriptionStatus {
  INITIALIZING = "INITIALIZING",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHING = "FINISHING",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}

export enum DomainCode {
  COL = "COL",
  LAW = "LAW",
  MED = "MED",
}
