import { useRef } from "react";
import { environments } from "_constants/env";
import { SessionError } from "_enums/Error";
import { MessageType } from "_enums/MessageType";
import { ISessionInfo } from "_interfaces/Session";
import { IMessage, ITranscript } from "_interfaces/Transcript";
import { CredentialsManager } from "_services/CredentialsManager";
import { useEnvironment } from "./useEnvironment";

interface UseWsReturn {
  openSession: (onMessage: (message: IMessage) => void) => Promise<WebSocket>;
  closeSession: (keepId?: boolean) => Promise<void>;
  getSessionInfo: () => ISessionInfo | null;
  sendData: (data: ArrayBuffer) => void;
}

const useWs = (): UseWsReturn => {
  const ws = useRef<WebSocket>();
  const sessionInfo = useRef<ISessionInfo | null>(null);

  const [activeEnv] = useEnvironment();

  /***
   * Opens WS session
   * @param onMessage Callback for receiving WS transcript messages
   * @throws error, if session opening was not successful
   */
  const openSession = async (onMessage: (message: IMessage) => void): Promise<WebSocket> => {
    if (ws.current !== undefined) {
      throw "Cannot open a new session before previous is closed!";
    }

    const credentials = await CredentialsManager.getValidCredentials();
    if (!credentials) throw SessionError.INVALID_CREDENTIALS;

    const queryParams = [`access_token=${credentials.accessToken.token}`];
    // Use the same session, if WS connection was opened before
    if (sessionInfo.current) {
      queryParams.push(`session_id=${sessionInfo.current.sessionId}`);
    }

    return new Promise((resolve, reject) => {
      ws.current = new WebSocket(`${environments[activeEnv].BASE_WS_URL}?${queryParams.join("&")}`);

      ws.current.onclose = (_) => {
        ws.current = undefined;
        reject("Session closed unexpectedly!");
      };

      ws.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        switch (message.messageType) {
          case MessageType.INFO:
            sessionInfo.current = {
              sessionId: message.sessionId,
              isNew: message.isNew,
              previousRecordings: message.previousRecordings,
            };
            console.log("Session opened successfully!");
            console.table(sessionInfo.current);
            resolve(ws.current);
            break;
          case MessageType.TRANSCRIPT:
            onMessage(message);
            break;
          default:
            console.log("Invalid message type received through websocket:", message.messageType);
        }
      };

      ws.current.onerror = (e) => {
        reject("Websocket error occurred:" + e);
      };
    });
  };

  /***
   * Closes WS session
   * @param keepId Whether to keep ID in memory to later use it for continuing the session
   * @throws error, if session closing was not successful
   */
  const closeSession = async (keepId: boolean = true): Promise<void> => {
    if (ws.current === undefined) {
      throw "No active session to close.";
    }
    if (!keepId) sessionInfo.current = null;

    const closePromise: Promise<void> = new Promise((resolve, reject) => {
      ws.current.onclose = (_) => {
        ws.current = undefined;
        console.log("Session closed successfully!");
        resolve();
      };

      ws.current.onerror = (e) => {
        reject(e);
      };
    });
    ws.current.send(new ArrayBuffer(0));
    return closePromise;
  };

  const sendData = (data: ArrayBuffer) => {
    if (ws.current === undefined) {
      throw "No active session. Cannot send the data.";
    }
    ws.current.send(data);
  };

  /***
   * @returns session info, if available
   */
  const getSessionInfo = (): ISessionInfo | null => {
    return sessionInfo.current;
  };

  return { openSession, closeSession, sendData, getSessionInfo };
};

export default useWs;
