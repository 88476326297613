import { Alert, Button, Group, LoadingOverlay, Modal, Select, Stack, Switch, Text, ThemeIcon } from "@mantine/core";
import React, { forwardRef, useEffect, useState } from "react";
import { Certificate, FileText, Icon, Stethoscope } from "tabler-icons-react";
import { sl } from "_assets/localization/sl";
import { AlertType } from "_enums/AlertType";
import { ConfigurationError } from "_enums/Error";
import { DomainCode } from "_enums/Transcription";
import useAuth from "_hooks/useAuth";
import { fetchConfiguration } from "_services/api/fetchConfiguration";
import { patchConfiguration } from "_services/api/patchConfiguration";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: DomainCode;
  label: string;
  description: string;
  image: Icon;
}

interface AlertMessage {
  text: string;
  type: AlertType;
}

export const SettingsModal = ({ isOpened, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [domain, setDomain] = useState<DomainCode>(DomainCode.COL);
  const [doPunctuation, setDoPunctuation] = useState<boolean>(false);

  const [message, setMessage] = useState<AlertMessage>();

  const { logout } = useAuth();

  const getConfiguration = async () => {
    setIsLoading(true);
    try {
      const config = await fetchConfiguration();
      if (
        config.transcriptionDomain !== undefined &&
        Object.values(DomainCode).includes(config.transcriptionDomain as DomainCode) &&
        config.transcriptionDoPunctuation !== undefined
      ) {
        setDomain(config.transcriptionDomain as DomainCode);
        setDoPunctuation(config.transcriptionDoPunctuation);
      } else {
        setMessage({
          text: sl.settings.settingsFetchFailed,
          type: AlertType.ERROR,
        });
      }
    } catch (error) {
      setMessage({
        text: sl.settings.settingsFetchFailed,
        type: AlertType.ERROR,
      });
    }
    setIsLoading(false);
  };

  const handleSaveConfiguration = async () => {
    setIsSaving(true);
    try {
      await patchConfiguration({
        transcriptionDomain: domain,
        transcriptionDoPunctuation: doPunctuation,
      });
      setTimeout(() => {
        setIsSaving(false);
        setMessage({
          text: sl.settings.settingsSaveSuccess,
          type: AlertType.SUCCESS,
        });
      }, 300);
    } catch (error) {
      console.error(error);
      if (error === ConfigurationError.INVALID_CREDENTIALS) {
        setMessage({
          text: sl.error.sessionTimeout,
          type: AlertType.ERROR,
        });
        setTimeout(async () => {
          await logout();
        }, 2000);
      } else {
        setTimeout(() => {
          setIsSaving(false);
          setMessage({
            text: sl.settings.settingsSaveFailure,
            type: AlertType.ERROR,
          });
        }, 300);
      }
    }
  };

  useEffect(() => {
    if (isOpened) getConfiguration();
    setMessage(undefined);
  }, [isOpened]);

  useEffect(() => {
    setMessage(undefined);
  }, [domain, doPunctuation]);

  useEffect(() => {
    if (isSaving) setMessage(undefined);
  }, [isSaving]);

  const data = [
    {
      image: FileText,
      label: sl.domains.generalTitle,
      value: DomainCode.COL,
      description: sl.domains.generalDescription,
      color: "blue",
    },

    {
      image: Certificate,
      label: sl.domains.lawTitle,
      value: DomainCode.LAW,
      description: sl.domains.lawDescription,
      color: "violet",
    },
    {
      image: Stethoscope,
      label: sl.domains.medicalTitle,
      value: DomainCode.MED,
      description: sl.domains.medicalDescription,
      color: "green",
    },
  ];

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, color, ...others }: ItemProps, ref) => {
      const TheIcon = image;
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            <ThemeIcon radius={"xl"} size={"lg"} color={color} variant={"light"}>
              <TheIcon size={18} />
            </ThemeIcon>

            <div>
              <Text size="sm">{label}</Text>
              <Text size="xs" color="dimmed">
                {description}
              </Text>
            </div>
          </Group>
        </div>
      );
    }
  );

  const handleDomainChange = (value: DomainCode) => {
    setDomain(value);
  };

  const handleDoPunctuationChange = (event) => {
    setDoPunctuation(event.currentTarget.checked);
  };

  return (
    <Modal opened={isOpened} title={sl.settings.settings} onClose={onClose} size="xs" centered>
      <LoadingOverlay visible={isLoading} />
      <Stack>
        <Stack py={12}>
          <Select
            label={sl.settings.textDomain}
            value={domain}
            onChange={handleDomainChange}
            itemComponent={SelectItem}
            data={data}
            searchable
            maxDropdownHeight={400}
            nothingFound={sl.settings.noOptions}
            filter={(value, item) =>
              item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.description.toLowerCase().includes(value.toLowerCase().trim())
            }
          />
          <Switch checked={doPunctuation} onChange={handleDoPunctuationChange} label={sl.settings.autoPunctuation} />
        </Stack>
        {message && (
          <Alert
            color={message.type === AlertType.SUCCESS ? "green" : message.type === AlertType.ERROR ? "red" : "blue"}
          >
            <Text
              color={message.type === AlertType.SUCCESS ? "green" : message.type === AlertType.ERROR ? "red" : "blue"}
              weight={500}
              size="sm"
            >
              {message.text}
            </Text>
          </Alert>
        )}
        <Button onClick={handleSaveConfiguration} loading={isSaving}>
          {sl.general.save}
        </Button>
      </Stack>
    </Modal>
  );
};
