import { Button, Modal, Stack, Text } from "@mantine/core";
import React from "react";
import { sl } from "_assets/localization/sl";
import useAuth from "_hooks/useAuth";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const LogoutModal = ({ isOpened, onClose }: Props) => {
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal opened={isOpened} title={sl.logout.logout} onClose={onClose} size="xs" centered>
      <Stack>
        <Text>{sl.logout.logoutMessage}</Text>
        <Stack spacing={8}>
          <Button onClick={handleLogout} color="red" variant="light">
            {sl.logout.logmeout}
          </Button>
          <Button onClick={onClose}>{sl.general.cancel}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
