import { Key } from "_enums/Key";
import { Credentials, Token } from "_interfaces/Credentials";
import { isTokenValid } from "_utils/Api";
import { refreshCredentials } from "_services/api/refreshCredentials";

export class CredentialsManager {
  private static getToken = (key: Key): Token | null => {
    if (key === Key.ACCESS_TOKEN || key === Key.REFRESH_TOKEN) {
      const tokenSerialized = localStorage.getItem(key);
      if (tokenSerialized) {
        const token: Token = JSON.parse(tokenSerialized);
        console.log(key, "successfully loaded from storage.");
        return token;
      } else {
        console.log(key, "was not found in storage.");
      }
    }
    return null;
  };

  private static storeToken = (key: Key, token: Token) => {
    if (key === Key.ACCESS_TOKEN || key === Key.REFRESH_TOKEN) {
      localStorage.setItem(key, JSON.stringify(token));
      console.log(key, "successfully set.");
    }
  };

  private static getAccessToken = (): Token | null => {
    return this.getToken(Key.ACCESS_TOKEN);
  };

  private static getRefreshToken = (): Token | null => {
    return this.getToken(Key.REFRESH_TOKEN);
  };

  static storeAccessToken = (accessToken: Token) => {
    return this.storeToken(Key.ACCESS_TOKEN, accessToken);
  };

  static storeRefreshToken = async (refreshToken: Token) => {
    return this.storeToken(Key.REFRESH_TOKEN, refreshToken);
  };

  static storeCredentials = (accessToken: Token, refreshToken: Token) => {
    this.storeAccessToken(accessToken);
    this.storeRefreshToken(refreshToken);
  };

  static clearCredentials = () => {
    localStorage.removeItem(Key.ACCESS_TOKEN);
    localStorage.removeItem(Key.REFRESH_TOKEN);
    console.log("Credentials cleared successfully.");
  };

  /***
   * Attempts to get the user credentials from local storage. Refreshes them, if access token is invalid, but refresh token still valid.
   */
  static getValidCredentials = async (): Promise<Credentials | null> => {
    try {
      const accessToken: Token | null = this.getAccessToken();
      const refreshToken: Token | null = this.getRefreshToken();
      if (accessToken && refreshToken) {
        if (isTokenValid(accessToken.expiryTimestamp)) {
          console.log("Access token is still valid!");
          return { accessToken, refreshToken };
        } else if (isTokenValid(refreshToken.expiryTimestamp)) {
          console.log("Refresh token is valid. Requesting new access token...");
          const newAccessToken: Token = await refreshCredentials(refreshToken);
          console.log("Access token refreshed!");
          this.storeAccessToken(newAccessToken);
          return { accessToken: newAccessToken, refreshToken };
        } else {
          console.log("No valid saved credentials available! Some invalid left over. Cleaning...");
          this.clearCredentials();
        }
      } else if (accessToken || refreshToken) {
        console.log("No valid saved credentials available! Some old left over. Cleaning...");
        this.clearCredentials();
      } else {
        console.log("No saved credentials available!");
      }
    } catch (error) {
      console.error("Error getting valid credentials:", error);
    }
    return null;
  };
}
