import {
  Affix,
  Button,
  Center,
  Image,
  PasswordInput,
  Stack,
  TextInput,
  Text,
  Anchor,
  Alert,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { AlertCircle, Mail } from "tabler-icons-react";
import { COMPANY_NAME, sl } from "_assets/localization/sl";
import { OnboardingModal } from "_components/OnboardingModal";
import { COMPANY_SITE, CONTACT_EMAIL } from "_constants/app";
import { Environment } from "_enums/Environment";
import { CommonError, LoginError } from "_enums/Error";
import useAuth from "_hooks/useAuth";
import { useEnvironment } from "_hooks/useEnvironment";
import useFirstRun from "_hooks/useFirstRun";

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [activeEnv, updateActiveEnv] = useEnvironment();
  const [environment, setEnvironment] = useState<string>("");

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [onboardingModalOpen, setOnboardingModalOpen] = useState<boolean>(false);

  const { login } = useAuth();
  const isFirstRun = useFirstRun();

  useEffect(() => {
    if (isFirstRun) {
      setOnboardingModalOpen(true);
    }
  }, [isFirstRun]);

  useEffect(() => {
    if (activeEnv) {
      setEnvironment(activeEnv.toString());
    }
  }, [activeEnv]);

  useEffect(() => {
    if (!activeEnv || environment !== activeEnv.toString()) {
      if (Object.values(Environment).includes(environment as Environment)) {
        updateActiveEnv(environment as Environment);
      }
    }
  }, [environment]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await login(username, password);
    } catch (error) {
      switch (error) {
        case LoginError.INVALID_DATA:
          setErrorMessage(sl.login.loginDataInvalid);
          break;
        case CommonError.NETWORK:
          setErrorMessage(sl.error.noInternet);
          break;
        default:
          setErrorMessage(sl.error.default);
      }
      setIsLoading(false);
    }
  };

  const handleEnvironmentChange = (e) => {
    const text = e.currentTarget.value;
    setEnvironment(text.toUpperCase());
  };

  const handleUsernameChange = (e) => {
    setUsername(e.currentTarget.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.currentTarget.value);
    setErrorMessage("");
  };

  const handleRequestAccess = () => {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${sl.login.requestAccessSubject}`;
  };

  const checkIfEnterPressed = (e) => {
    e.key === "Enter" && handleLogin();
  };

  return (
    <>
      <Stack
        sx={() => ({
          height: "100%",
        })}
        justify="center"
        p={16}
      >
        <Center pb={24}>
          <Image src={require("_assets/images/img_logo.png")} alt={COMPANY_NAME} width={164} />
        </Center>
        <TextInput
          onChange={handleEnvironmentChange}
          placeholder={sl.login.server}
          label={`${sl.login.server} - ${activeEnv}`}
          value={environment}
        />
        <TextInput
          onChange={handleUsernameChange}
          placeholder={sl.login.username}
          label={sl.login.username}
          value={username}
          required
          onKeyPress={checkIfEnterPressed}
        />
        <PasswordInput
          onChange={handlePasswordChange}
          placeholder={sl.login.password}
          label={sl.login.password}
          value={password}
          required
          onKeyPress={checkIfEnterPressed}
        />
        {errorMessage && (
          <Alert icon={<AlertCircle size={16} />} title={sl.login.loginFailedTitle} color="red" radius={"lg"}>
            {errorMessage}
          </Alert>
        )}
        <Button onClick={handleLogin} loading={isLoading}>
          {sl.login.login}
        </Button>
        <Center>
          <Tooltip label={CONTACT_EMAIL} withArrow openDelay={300}>
            <Button onClick={handleRequestAccess} variant="subtle" leftIcon={<Mail size={14} />}>
              {sl.login.requestAccess}
            </Button>
          </Tooltip>
        </Center>
      </Stack>
      <Affix position={{ bottom: 16, left: 0, right: 0 }}>
        <Center>
          <Anchor href={COMPANY_SITE} target="_blank" rel="noopener noreferrer">
            {sl.login.copyright}
          </Anchor>
        </Center>
      </Affix>
      <OnboardingModal isOpened={onboardingModalOpen} onClose={() => setOnboardingModalOpen(false)} />
    </>
  );
};

export default Login;
